import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import {
  CustomCarousel,
  IconEnum,
  Modal,
  SvgIcon,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { Link } from '~/common/models';
import { PortalProvider, usePortals } from 'react-portal-hook';
import {
  colorBlack,
  desktopMin,
  isAbsoluteUrl,
  tabletMax,
} from '~/common/utils';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';

export interface IFeatureTilesProps {
  id?: number;
  rowTileCount?: number;
  tiles?: IFeatureTile[];
  backgroundColor?: string;
  fontColor?: string;
  tileBackgroundColor?: string;
  tileFontColor?: string;
  carouselDescription?: string;
  carouselBackgroundColor?: string;
  carouselFontColor?: string;
  carouselTileBackgroundColor?: string;
  carouselTileFontColor?: string;
  displayInCarousel?: boolean;
  viewMedia?: keyof typeof ViewMediaEnum;
}

enum ViewMediaEnum {
  none = 'none',
  page = 'page',
  modal = 'modal',
}

interface IFeatureTile {
  header?: string;
  headerDisclaimer?: string;
  imageSrc?: string;
  imageDisclaimer?: string;
  imageDisclaimerFontColor?: string;
  videoSrc?: string;
  detailsUrl?: Link;
  embeddedMedia?: string;
  content?: string;
}

const breakpoints = {
  desktop: {
    breakpoint: { max: 4000, min: desktopMin },
    items: 3,
  },
  mobile: {
    breakpoint: { max: tabletMax, min: 0 },
    items: 1,
  },
};

interface ModalProps {
  closeModal: () => void;
}

const isPlaying = (_this?: HTMLVideoElement | null) => {
  if (!_this) return null;
  return !_this.paused && _this.currentTime > 0 && !_this.ended;
};

const onVideoEnded = (_this?: HTMLVideoElement | null) => {
  if (_this) {
    _this.currentTime = 0;
  }
};

const onVideoPlayPause = (
  _this?: HTMLVideoElement | null,
  playCallback?: (_this?) => void,
  pauseCallback?: (_this?) => void
) => {
  if (_this) {
    if (isPlaying(_this)) {
      if (pauseCallback) {
        pauseCallback(_this);
        return;
      }
      _this.pause();
    } else {
      if (playCallback) {
        playCallback(_this);
        return;
      }
      _this.play();
    }
  }
};

const renderDisclaimer = (tile) => {
  if (!tile.imageDisclaimer) return <></>;
  return (
    <TooltipDisclaimer className={cn(styles.ImageDisclaimer)}>
      <p
        dangerouslySetInnerHTML={{
          __html: tile.imageDisclaimer as string,
        }}
        style={{ color: `#${tile.imageDisclaimerFontColor || 'ffffff'}` }}
      />
    </TooltipDisclaimer>
  );
};

const FeatureTileHighlight: React.FC<IFeatureTile> = (props) => {
  const { videoSrc, imageSrc, embeddedMedia } = props;
  const modalVideoRef = useRef<HTMLVideoElement>(null);
  const [modalPlaying, setModalPlaying] = useState(false);

  const handleHighlightedVideoClick = (e) => {
    onVideoPlayPause(
      e.target.tagName === 'VIDEO'
        ? e.currentTarget || e.target
        : modalVideoRef.current,
      (el) => {
        el.play();
        setModalPlaying(true);
      },
      (el) => {
        el.pause();
        setModalPlaying(false);
      }
    );
  };

  return (
    <>
      {embeddedMedia ? (
        <div
          dangerouslySetInnerHTML={{
            __html: embeddedMedia as string,
          }}
        />
      ) : videoSrc ? (
        <>
          <video
            ref={modalVideoRef}
            disablePictureInPicture
            onEnded={(e: any) => {
              onVideoEnded(e.target);
            }}
            onClick={handleHighlightedVideoClick}
            preload="auto"
            src={videoSrc}
          />
          {!modalPlaying && (
            <SvgIcon
              type={IconEnum.play}
              color="#323334"
              fill="#323334"
              size="100%"
              onClick={handleHighlightedVideoClick}
            />
          )}
        </>
      ) : (
        <>
          <img src={imageSrc} loading="lazy" />
          {renderDisclaimer(props)}
        </>
      )}
    </>
  );
};

const FeatureTileModal: React.FC<ModalProps & IFeatureTile> = ({
  closeModal,
  ...rest
}) => {
  return (
    <Modal
      closeModal={closeModal}
      modalContentClass={cn(styles.ModalContent, {
        [styles.ModalContentEmbed]: rest.embeddedMedia,
      })}
      closeButtonColor={'#fff'}
      lockScroll
    >
      <div
        className={cn(styles.MediaContainerModal, {
          [styles.MediaContainerModalEmbed]: rest.embeddedMedia,
        })}
      >
        <FeatureTileHighlight {...rest} />
      </div>
    </Modal>
  );
};

const FeatureTilesComponent = React.memo(
  ({
    id,
    tiles,
    rowTileCount,
    backgroundColor,
    fontColor,
    tileBackgroundColor,
    tileFontColor,
    carouselDescription,
    carouselBackgroundColor,
    carouselFontColor,
    carouselTileBackgroundColor,
    carouselTileFontColor,
    displayInCarousel,
    viewMedia,
  }: IFeatureTilesProps) => {
    const _rowTileCount = rowTileCount || 3;
    const [playingIndex, setPlayingIndex] = useState(-1);
    const portalManager = usePortals();
    const parentRef = useRef<HTMLDivElement>(null);
    const [carouselTiles, setCarouselTiles] = useState<typeof tiles>(tiles);
    const [carouselInitialIndex, setCarouselInitialIndex] = useState(0);
    const [mainTile, setMainTile] = useState<IFeatureTile>();

    const [isMobile, setIsMobile] = useState(
      useMediaQuery(
        { query: `(max-width: ${tabletMax}px)` },
        undefined,

        (matches) => {
          setIsMobile(matches);
        }
      )
    );
    useRTETooltipPositionCorrector(isMobile);

    const setTiles = useCallback((url = window.location.href) => {
      if (displayInCarousel && tiles && tiles?.length > 0) {
        const idx = tiles.findIndex((x) => {
          if (!x.detailsUrl?.url) return false;
          const tileUrl = isAbsoluteUrl(x.detailsUrl?.url)
            ? new URL(x.detailsUrl?.url)
            : new URL(x.detailsUrl?.url, window.location.origin);
          const _url = new URL(url);
          return (
            tileUrl.pathname === _url.pathname ||
            tileUrl.pathname === `${_url.pathname}/`
          );
        });
        const _tiles = tiles.filter((_, i) => i !== idx);
        setCarouselTiles(_tiles);
        if (idx > -1) {
          setMainTile(tiles[idx]);
          const offset = isMobile ? 1 : _rowTileCount;
          const maxSlide = _tiles.length - offset;
          const slideIndex = idx > maxSlide ? maxSlide : idx;
          setCarouselInitialIndex(slideIndex);
        }
      }
    }, []);
    useEffect(() => setTiles(), [setTiles, isMobile]);

    const viewMediaClickable = (_tile: IFeatureTile) => {
      if (displayInCarousel) return true;
      return (
        _tile &&
        ((!isMobile && viewMedia === ViewMediaEnum.modal) ||
          (viewMedia === ViewMediaEnum.page && _tile.detailsUrl?.url))
      );
    };

    const showArrows = (compareCount = _rowTileCount) => {
      if (!(carouselTiles && carouselTiles.length > 0)) return false;
      return carouselTiles.length > compareCount;
    };

    const renderTiles = () => {
      if (!(carouselTiles && carouselTiles.length > 0)) return <></>;
      const refs = Array(carouselTiles.length)
        .fill(0)
        .map(() => React.createRef<HTMLVideoElement>());

      const handleVideoIconClick = (idx) => {
        refs.forEach((ref, i) => {
          onVideoPlayPause(
            ref.current,
            (e) => {
              if (i === idx) {
                setPlayingIndex(idx);
                e.play();
              }
            },
            (e) => {
              e.pause();
              if (i === idx) {
                setPlayingIndex(-1);
              }
            }
          );
        });
      };

      const handleModalView = (tile) => {
        portalManager.open(
          (portal) => <FeatureTileModal closeModal={portal.close} {...tile} />,
          parentRef.current ? { appendTo: parentRef.current } : {}
        );
      };

      const handleViewMedia = (idx) => {
        const _tile = carouselTiles[idx];
        if (displayInCarousel && _tile.detailsUrl?.url) {
          window.location.href = _tile.detailsUrl.url;
        } else if (!displayInCarousel && viewMediaClickable(_tile)) {
          if (viewMedia === ViewMediaEnum.page && _tile.detailsUrl?.url) {
            if (_tile.detailsUrl.target === '_blank') {
              window.open(_tile.detailsUrl.url, '_blank');
            } else {
              window.location.href = _tile.detailsUrl.url;
            }
          } else if (viewMedia === ViewMediaEnum.modal) {
            if (playingIndex === idx) {
              onVideoPlayPause(refs[idx].current);
              setPlayingIndex(-1);
            }
            handleModalView(_tile);
          }
        }
      };

      return carouselTiles.map((tile, idx) => {
        return (
          <div
            key={idx}
            className={cn(styles.FeatureTile, {
              [styles.FeatureTileHover]: viewMediaClickable(tile),
            })}
            style={{
              ...(!isMobile && !displayInCarousel
                ? { flexBasis: `${100 / _rowTileCount}%` }
                : { flexBasis: '100%' }),
            }}
            onClick={() => handleViewMedia(idx)}
          >
            <div>
              <div
                className={cn(styles.MediaContainer, {
                  [styles.MediaContainerEmbed]: tile.embeddedMedia,
                })}
              >
                {tile.embeddedMedia ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tile.embeddedMedia as string,
                    }}
                  />
                ) : tile.videoSrc ? (
                  <>
                    <video
                      ref={refs[idx]}
                      disablePictureInPicture
                      onEnded={(e: any) => {
                        onVideoEnded(e.target || refs[idx].current);
                        setPlayingIndex(-1);
                      }}
                      preload="auto"
                      src={tile.videoSrc}
                    />
                    <div>
                      <SvgIcon
                        type={
                          playingIndex === idx
                            ? IconEnum.pauseCircle
                            : IconEnum.playCircle
                        }
                        color="#fff"
                        size={1.75}
                        onClick={(e) => {
                          handleVideoIconClick(idx);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  </>
                ) : tile.imageSrc ? (
                  <>
                    <img src={tile.imageSrc} loading="lazy" />
                    {renderDisclaimer(tile)}
                  </>
                ) : (
                  <></>
                )}
              </div>
              {(tile.content || tile.header) && (
                <div
                  style={{
                    ...(displayInCarousel
                      ? { backgroundColor: `#${carouselTileBackgroundColor}` }
                      : tileBackgroundColor
                      ? {
                          backgroundColor: `#${tileBackgroundColor}`,
                        }
                      : {}),
                    ...(displayInCarousel
                      ? { color: `#${carouselTileFontColor}` }
                      : tileFontColor
                      ? { color: `#${tileFontColor}` }
                      : {}),
                  }}
                  className={styles.ContentContainer}
                >
                  <div>
                    {tile.header && (
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: tile.header as string,
                        }}
                      />
                    )}
                    {tile.headerDisclaimer && (
                      <TooltipDisclaimer
                        followCursor
                        disclaimer={tile.headerDisclaimer}
                        className={styles.DisclaimerContainer}
                        tooltipClassName={styles.DisclaimerTooltip}
                        contentClassName={styles.DisclaimerContent}
                        color={
                          tileFontColor !== colorBlack
                            ? tileFontColor
                            : undefined
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      });
    };

    return (
      <div
        ref={parentRef}
        key={id}
        id={`cid-${id}`}
        className={styles.FeatureTilesComponent}
      >
        {mainTile && displayInCarousel && (
          <div
            className={styles.MainTileContainer}
            style={{
              ...(backgroundColor
                ? {
                    backgroundColor: `#${backgroundColor}`,
                  }
                : {}),
              ...(fontColor
                ? {
                    color: `#${fontColor}`,
                  }
                : {}),
            }}
          >
            <div
              className={cn(styles.HighlightedMediaContainer, {
                [styles.HighlightedMediaContainerEmbed]: mainTile.embeddedMedia,
              })}
              style={{
                ...(mainTile.videoSrc || mainTile.embeddedMedia
                  ? { cursor: 'pointer' }
                  : {}),
              }}
            >
              <FeatureTileHighlight {...mainTile} />
            </div>
            <div
              className={styles.MainTileContent}
              dangerouslySetInnerHTML={{ __html: mainTile.content as string }}
            />
          </div>
        )}
        <div className={styles.FeatureTilesWrapper}>
          <div
            className={cn(styles.FeatureTilesContainer, {
              [styles.FeatureTilesContainerCarousel]:
                displayInCarousel && !isMobile,
            })}
            style={
              (!displayInCarousel && backgroundColor) ||
              (displayInCarousel && carouselBackgroundColor)
                ? {
                    backgroundColor: `#${
                      displayInCarousel
                        ? carouselBackgroundColor || 'f2f3f4'
                        : backgroundColor
                    }`,
                  }
                : {}
            }
          >
            {displayInCarousel && carouselDescription && (
              <h3
                className={styles.CarouselDescription}
                style={{
                  ...(carouselFontColor
                    ? {
                        color: `#${carouselFontColor}`,
                      }
                    : {}),
                }}
              >
                {carouselDescription}
              </h3>
            )}
            <MediaQuery minWidth={0}>
              <div
                className={cn(styles.FeatureTiles, {
                  [styles.FeatureTilesCarousel]: displayInCarousel && !isMobile,
                  [styles.FeatureTilesCarouselMobile]:
                    displayInCarousel && isMobile,
                })}
              >
                {displayInCarousel ? (
                  <CustomCarousel
                    carouselArrowColor={carouselFontColor}
                    responsive={{
                      ...breakpoints,
                      desktop: {
                        ...breakpoints.desktop,
                        items: _rowTileCount || 3,
                      },
                    }}
                    showDots={isMobile && showArrows(1)}
                    removeArrowOnDeviceType={['mobile']}
                    goToSlide={carouselInitialIndex}
                    swipeable={isMobile}
                    arrows={showArrows()}
                  >
                    {renderTiles()}
                  </CustomCarousel>
                ) : (
                  renderTiles()
                )}
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
);

export const FeatureTiles = React.memo((props: IFeatureTilesProps) => {
  return (
    <PortalProvider>
      <FeatureTilesComponent {...props} />
    </PortalProvider>
  );
});
